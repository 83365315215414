<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
          type="button"
          @click="print()"
          class="btn btn-info pull-right"
          title="Print"
        >
          <i class="fa fa-print"></i> {{ $t("print") }}
        </button>&nbsp;
        <button
          v-if="state.detail.unit_id == 1"
          @click="onDownloadGubernur"
          :loading="printDispoLoadingGubernur"
          :disabled="printDispoLoadingGubernur"
          class="btn btn-sm btn-success"
          title="Print"
        >
          <i
            v-if="printDispoLoadingGubernur"
            class="fa fa-spinner fa-pulse fa-fw mr-1"
          ></i>
          <span
            v-if="!printDispoLoadingGubernur"
            class="badge badge-pill badge-light mr-1"
          >PDF</span>
          Gubernur
        </button>&nbsp;
        <button
          v-if="state.detail.unit_id == 1"
          @click="onDownloadWaGub"
          :loading="printDispoLoadingWaGub"
          :disabled="printDispoLoadingWaGub"
          class="btn btn-sm btn-success"
          title="Print"
        >
          <i
            v-if="printDispoLoadingWaGub"
            class="fa fa-spinner fa-pulse fa-fw mr-1"
          ></i>
          <span
            v-if="!printDispoLoadingWaGub"
            class="badge badge-pill badge-light mr-1"
          >PDF</span>
          Wakil Gubernur
        </button>&nbsp;
          <button
            @click="onDownload"
            :loading="printDispoLoading"
            :disabled="printDispoLoading"
            class="btn btn-sm btn-success"
            title="Print"
          >
            <i
              v-if="printDispoLoading"
              class="fa fa-spinner fa-pulse fa-fw mr-1"
            ></i>
            <span
              v-if="!printDispoLoading"
              class="badge badge-pill badge-light mr-1"
              >PDF</span
            >
            Print Lembar Disposisi
          </button>&nbsp;
        </span>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab v-if="state.detail.disposs == 1" title="Detail Disposisi">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Nomor Surat</small>
                <h6>{{ state.detail.nomorsurat }}</h6>
                <small class="text-muted">Penerima</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.penerima_stk"
                      v-bind:key="key"
                    >
                      <p>{{ item.text }}</p>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Personal</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.penerima_prs"
                      v-bind:key="key"
                    >
                      <p>{{ item.text }}</p>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Instruksi</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.perintah_dispo"
                      v-bind:key="key"
                    >
                      <p>{{ item.text }}</p>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Catatan</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in state.detail.catatan"
                      v-bind:key="key"
                    >
                      <b>{{ item.unit_name }}</b
                      >&nbsp;
                      <p>{{ item.catatan }}</p>
                    </li>
                  </ul>
                </h6>
              </div>
            </gtTab>
            <gtTab title="Detail Surat">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Tanggal Surat</small>
                <h6>
                  {{
                    state.detail.tanggal_surat === ""
                      ? "-"
                      : formatDate(state.detail.tanggal_surat)
                  }}
                </h6>
                <small class="text-muted">Dari</small>
                <h6>
                  {{
                    state.detail.asalsurat === "" ? "-" : state.detail.asalsurat
                  }}
                </h6>
                <small class="text-muted">Jenis Dokumen / Nama Dokumen</small>
                <h6></h6>
                <small class="text-muted">Sifat Surat</small>
                <h6>
                  {{ state.detail.sifat === "" ? "-" : state.detail.sifat }}
                </h6>
                <small class="text-muted">Kecepatan Surat</small>
                <h6></h6>
              </div>
              <div class="col-md-6 pull-right">
                <small class="text-muted">Ringkasan Surat</small>
                <!-- <h6>{{ state.detail.description }}</h6> -->
                <h6>
                  <span
                    v-html="
                      state.detail.description == ''
                        ? '-'
                        : state.detail.description
                    "
                  ></span>
                </h6>
                <small class="text-muted">Nomor Surat</small>
                <h6>
                  {{
                    state.detail.nomor_surat === ""
                      ? "-"
                      : state.detail.nomor_surat
                  }}
                </h6>
                <small class="text-muted">Nomor Kendali</small>
                <h6>{{ state.detail.no_opd }}</h6>
                <small class="text-muted">Halaman / Lampiran</small>
                <h6>
                  {{ state.detail.pages === "" ? "-" : state.detail.pages
                  }}{{ " / " }}
                  {{ state.detail.jumlah === "" ? "-" : state.detail.jumlah }}
                  {{
                    state.detail.document_lampiran === ""
                      ? "-"
                      : state.detail.document_lampiran.text
                  }}
                </h6>
                <small class="text-muted">Kepada</small>
                <h6 v-if="state.detail.receiver === ''">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in state.detail.receiver"
                      :key="rec.value"
                    >
                      <td>{{ index + 1 + ". " + rec.text }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>
                </h6>
                <small class="text-muted">Tembusan</small>
                <h6 v-if="state.detail.receiver2 === ''">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in state.detail.receiver2"
                      :key="rec.value"
                    >
                      <td>{{ index + 1 + ". " + rec.text }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>

                  <!-- <table>

                      <tr v-for="(rec,index) in state.detail.carbon" :key="rec.value">
                        <td>{{ (index+1) + ". " + rec.text }}</td>
                        <td style="margin-left:10px;">
                          <i v-if="rec.approval==2" class="fa fa-envelope" style="cursor:pointer;background:blue;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                          <i v-else-if="rec.approval==1" class="fa fa-envelope" style="cursor:pointer;background:green;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                          <i v-else-if="rec.approval==3" class="fa fa-envelope" style="cursor:pointer;background:red;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                        </td>
                      </tr>

                    </table> -->
                </h6>
              </div>
              <hr />

              <div class="col-md-6 pull-left">
                <small class="text-muted">Catatan Konfirmasi / Retur </small>
                <h6>
                  <span
                    v-html="
                      state.detail.catatan_konfirmasi == ''
                        ? '-'
                        : state.detail.catatan_konfirmasi
                    "
                  ></span>
                </h6>
              </div>
              <div class="col-md-12">
                <br />
                <h6
                  v-if="
                    state.detail.files == null ||
                    state.detail.files == undefined
                  "
                >
                  -
                </h6>
                <h6 v-else-if="!state.detail.files.length">-</h6>
                <table v-else class="table table-stripped">
                  <tr>
                    <th>File</th>
                    <th>Pengirim</th>
                    <!-- <th>Tanggal</th> -->
                    <th>Buka File</th>
                  </tr>
                  <tr
                    v-for="file in state.detail.files"
                    v-bind:key="file.filename"
                  >
                    <td>
                      {{ file.filename }}
                    </td>
                    <td>
                      {{
                        state.detail.senderUnit === undefined
                          ? "-"
                          : state.detail.senderUnit
                      }}
                      <!-- {{ file.nameasal===undefined?'-':file.nameasal.name }} -->
                    </td>
                    <!-- <td>
                      {{ formatDate(file.tanggal_surat.date) }}
                      {{ file.tanggal_surat.date }}
                    </td> -->
                    <td>
                      <!-- <a
                        class="btn btn-success text-white"
                        @click="downloadForce(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp; -->
                      <a
                        @click="prev(file.uuid)"
                        class="btn btn-info text-white"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                      <!-- <a
                        class="btn btn-info text-white"
                        @click="downloadForce(file.url)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a> -->
                    </td>
                  </tr>
                </table>
              </div>
            </gtTab>

            <gtTab title="Tracking">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <TreeView class="item" :model="treeData" />
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>

            <!-- <gtTab title="History">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h5>
                        <ol>
                          <li>
                            <span
                              v-if="state.detail.history == undefined"
                            ></span>
                            <span
                              v-else
                              v-html="state.detail.history.document"
                            ></span>
                          </li>
                          <span v-if="state.detail.history == undefined"></span>
                          <li
                            v-else-if="
                              state.detail.history.konfirmasi != undefined &&
                              state.detail.history.konfirmasi != ''
                            "
                          >
                            <span
                              v-html="state.detail.history.konfirmasi"
                            ></span>
                          </li>
                          <span v-if="state.detail.history == undefined"></span>
                          <li
                            v-else-if="
                              state.detail.history.disposition != undefined &&
                              state.detail.history.disposition != ''
                            "
                          >
                            <span
                              v-html="state.detail.history.disposition"
                            ></span>
                          </li>
                        </ol>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </gtTab> -->

            <gtTab title="Retur Mail" v-if="state.detail.decline != undefined">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h5><b>Alasan Retur Surat</b></h5>
                      <!-- <h5>{{ state.detail.confirmation }}</h5> -->
                      <h5>{{ state.detail.catatan_konfirmasi }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>
          </gtTabs>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div></div>
        </div>
      </div>
      <span class="pull-right">
        <span v-if="!state.detail.send">
          <button
            type="button"
            @click="goSend()"
            class="btn btn-info"
            title="Kirim"
          >
            Kirim</button
          >&nbsp;</span
        >
        <span v-else>
          <template>
            <button
              type="button"
              @click="goDisposisi()"
              v-if="state.detail.disposss == false"
              class="btn btn-inverse"
              title="Disposisi"
            >
              Disposisi</button
            >&nbsp;
            <!-- v-if="
                state.detail.konfirmasi === '1' &&
                state.detail.close != '1' &&
                canDispo 
              " -->
            <button
              type="button"
              @click="goSelesai()"
              v-if="state.detail.disposss == false"
              class="btn btn-danger"
              title="Selesai"
            >
              Selesai</button
            >&nbsp;
            <button
              type="button"
              @click="goConfirm()"
              v-if="state.detail.disposss == false"
              class="btn btn-success"
              title="Selesai"
            >
              Retur</button
            >&nbsp;
            <!-- v-if="
                state.detail.konfirmasi === '1' && state.detail.close != '1'
              " -->

            <!-- <button
              type="button"
              @click="goConfirm()"
              v-if="
                state.detail.approval != '3' && state.detail.konfirmasi != '1'
              "
              class="btn btn-inverse"
              title="Konfirmasi"
            >
              Konfirmasi</button
            >&nbsp; -->
          </template>
        </span>
        <!-- <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button> -->

        <button
          type="button"
          @click="showModal({ type: 'cancel' })"
          class="btn btn-inverse"
          title="Kembali"
        >
          Kembali</button
        >&nbsp;
        <!-- <button
          type="button"
          @click="goTandaiAtasan()"
          v-if="state.detail.atasan == '0'"
          class="btn btn-inverse"
          title="Tandai Surat Atasan"
        >
          Butuh Perhatian Atasan
        </button> -->
      </span>
    </div>

    <ModalDefault
      v-show="isVisible"
      :title="modalTitle"
      :content="modalContent"
      :action="ActionChangeable"
      :confirmation="isConfirmation"
      @close="onClose"
    />

    <!-- <div class="card card-body printableArea">
      <h4>Petunjuk</h4>
      <table>
        <tr>
          <td>
            <span class="btn-sm btn-inverse">Konfirmasi</span>
          </td>
          <td style="font-size: 13px">Untuk mengkonfirmasi surat masuk</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <span class="btn btn-inverse">Disposisi</span>
          </td>
          <td style="font-size: 13px">
            Untuk mendisposisi surat masuk dengan cara mengkonfirmasi terlebih
            dahulu sebelum mendisposisi
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <span class="btn-sm btn-danger">Selesai</span>
          </td>
          <td style="font-size: 13px">
            Untuk menyelesaikan surat ketika surat tersebut hanya akan di tindak
            lanjuti oleh penerima surat
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <span class="btn-sm btn-success">
              <i class="mdi mdi-printer"></i>Print Lembar Disposisi
            </span>
          </td>
          <td style="font-size: 13px">Untuk mencetak lembar disposisi</td>
        </tr>
      </table>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import { RotateSquare5 } from "vue-loading-spinner";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      myHTML: "",
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      printDispoLoading: false,
      printDispoLoadingGubernur: false,
      printDispoLoadingWaGub: false,
    };
  },
  computed: {
    canConfirm() {
      if (this.$store.state.profile.permissions.document_in_confirm) {
        return this.$store.state.profile.permissions.document_in_confirm.create;
      }
      return false;
    },
    canDispo() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
    treeData() {
      return this.$store.state.documentIn.detail.tracking !== undefined
        ? this.$store.state.documentIn.detail.tracking
        : {};
    },
    items() {
      var data = [];
      if (this.$store.state.documentIn.items) {
        for (
          let index = 0;
          index < this.$store.state.documentIn.items.items.length;
          index++
        ) {
          let element = this.$store.state.documentIn.items.items[index];
          data[index] = {
            id: element.id,
            agenda_number: element.agenda_number,
            nomor_surat: element.nomorsurat,
            subject: element.subject,
            sifat: element.sifat,
            tanggal_diterima: element.tanggal_diterima,
            redaksi: element.redaksi,
            asal_surat: element.asalsurat,
            confirmation: element.confirmation,
            confirm: element.confirm,
            close: element.close,
          };
        }
      }
      return data;
    },

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.documentIn;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
    tanggalsurat() {
      let text = "-";
      if (this.state.detail != undefined) {
        text = this.formatDate(this.state.detail.tanggal_surat);
      }
      return text;
    },
    tanggalditerima() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggalditerima == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggalditerima);
        }
      }
      return text;
    },
    tanggalselesai() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggal_selesai == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggal_selesai);
        }
      }
      return text;
    },

    dataPenerima() {
      var data = [];
      const penerimas = this.state.detail.penerima;
      penerimas.forEach(function (value, i) {
        // data[i] =
        //   {
        //     "text" : "- " + value.text,
        //     "style" : "header4"
        //   };

        data[i] = {
          table: {
            body: [
              [{ text: value.text }, {}],
              // [{}]
            ],
          },
        };
      });
      return data;
    },

    dataIntruksi() {
      var data = [];
      const intruksi = this.state.detail.intruksi;
      intruksi.forEach(function (value, i) {
        // data[i] = {
        //   "text" : "- " + value.text,
        //   "style" : "header4",
        // };
        data[i] = {
          table: {
            body: [
              ["Col1", "Col2", "Col3"],
              ["1", "2", "3"],
              ["1", "2", "3"],
            ],
          },
        };
      });
      return data;
    },

    urlLembarDispo() {
      return (
        this.baseUrl +
        "/document_in/" +
        this.$route.params.id +
        "/disposition_sheet"
      );
    },
  },
  mounted() {
    this.detailLoad();
    // this.getUserDispo(query);
  },
  methods: {
    showModalNote(option) {
      this.isVisible = true;
      this.modalTitle = "Catatan";
      this.modalContent = "<table class='table table-bordered'>";
      this.modalContent +=
        "<thead><tr><td><b>Penerima</b></td><td><b>Jawaban</b></td></tr></thead>";
      this.modalContent += "<tbody>";
      for (let index = 0; index < option.length; index++) {
        this.modalContent +=
          "<tr><td>" +
          option +
          "</td><td>" +
          option[index].catatan +
          "</td></tr>";
      }
      this.modalContent += "</tbody>";
      this.modalContent += "</table>";
    },
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      const user = localStorage.getItem("user");
      this.$store.commit("documentIn/STATE", state);
      this.$store.dispatch("documentIn/getDocumentInById", this.$route.params);
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    checkExtensi(file) {
      // /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined;
      // if (
      //   /[^.]+$/.exec(file)[0] === "docx" ||
      //   /[^.]+$/.exec(file)[0] === "doc" ||
      //   /[^.]+$/.exec(file)[0] === "xls"
      // ) {
      if (
        file.includes("docx") ||
        file.includes("doc") ||
        file.includes("xls")
      ) {
        return false;
      } else {
        return true;
      }
    },
    newTab(file) {
      window.open(file, file);
    },
    goDisposisi() {
      this.$store.dispatch("documentIn/onDisposisi", this.$route.params);
    },
    goBack() {
      this.$router.push("/documentIn");
    },
    print() {
      var documentDefinition = {
        content: [
          {
            text: [
              {
                text: "PEMERINTAH DAERAH\n",
                fontSize: 16,
                bold: true,
              },
              {
                text: "DAERAH ISTIMEWA YOGYAKARTA\n",
                fontSize: 23,
                bold: true,
              },
              {
                text: this.state.detail.datatelp,
                fontSize: 14,
              },
            ],
            alignment: "center",
            marginBottom: 3,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                x2: 515,
                y1: 0,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                x2: 515,
                y1: 0,
                y2: 0,
                lineWidth: 2,
              },
            ],
            marginTop: 3,
            marginBottom: 10,
          },
          {
            columns: [
              {
                text: [
                  {
                    width: "20px",
                    text: "Asal Surat               ",
                  },
                  {
                    width: "auto",
                    text: " : ",
                  },
                  {
                    width: "auto",
                    text: this.state.detail.asalsurat,
                  },
                ],
              },
            ],
            marginTop: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Nomor Surat          ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.nomorsurat,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Tanggal surat        ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.tanggal_surat,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Perihal                    ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.description,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Lampiran                ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: "-",
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Nomor Kendali      ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.no_opd,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Tanggal diteruskan ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.tanggal_surat,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Dikirim ke               ",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                // text: this.state.detail.senderUnit,
                text: this.state.detail.testignya,
              },
              {
                width: "auto",
                text: "",
              },
            ],
            marginBottom: 10,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                x2: 515,
                y1: 0,
                y2: 0,
                lineWidth: 2,
              },
            ],
            marginTop: 3,
            marginBottom: 3,
          },
          {
            table: {
              marginTop: 5,
              headerRows: 0,
              widths: ["*"],
              body: [
                [
                  {
                    columns: [
                      {
                        text: "",
                        alignment: "center",
                        margin: [0, 10],
                      },
                    ],
                  },
                ],
              ],
            },
            marginBottom: 40,
          },
        ],
        pageMargins: [40, 60, 40, 60],
        pageSize: "A4",
      };
      pdfMake.createPdf(documentDefinition).print();
    },
    goTandaiAtasan() {
      const data = {
        id: this.$route.params.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("documentIn/submitTandaiAtasan", data);
    },

    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_in",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    downloadDocument(filepath, filename) {
      axios
        .post("document_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadForce(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    downloadDisposition(filepath, filename) {
      axios
        .post("disposition_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // prev(string) {
    //   let routeData = this.$router.resolve({name:'preview.index',
    //     params:{
    //       document_type:'document_in',
    //       id:this.$route.params.id,
    //       file:string
    //     }
    //     });
    //     window.open(routeData.href, "_blank");
    // },

    goConfirm() {
      this.$store.dispatch("documentIn/onConfirm");
    },
    goSelesai() {
      this.$store.dispatch("documentIn/onClose");
    },
    goSend() {
      this.$store.dispatch(
        "documentIn/goSend",
        JSON.stringify({ document: this.$route.params.id })
      );
    },

    goReturn() {
      this.$store.dispatch("documentIn/onReturn");
    },
    approve(val) {
      let payload = {
        document: this.$route.params.id,
        approval: val,
        message: this.myHTML,
      };
      this.$store.dispatch("documentIn/approveDoc", JSON.stringify(payload));
    },
    onDownloadLembarDispo() {
      var context = this;
      context.printDispoLoading = true;
      const url = context.urlLembarDispo;

      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          // https://stackoverflow.com/a/51514660
          let url = window.URL.createObjectURL(res.data);
          let link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          link.remove();

          context.printDispoLoading = false;
        })
        .catch(function (error) {
          // https://github.com/axios/axios#handling-errors

          context.printDispoLoading = false;

          let message = "";
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);

            if (error.response.data.message) {
              message = error.response.data.message;
            } else {
              // TODO: handle expected HTTP status codes with a proper message,
              // otherwise return a generic message.
              message =
                "Terjadi kesalahan, silakan hubungi pengelola aplikasi.";
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);

            message =
              "Terjadi kesalahan di server, silakan coba lagi nanti atau hubungi pengelola aplikasi.";
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message || error);

            message =
              "Terjadi kesalahan, silakan cek koneksi internet Anda atau hubungi pengelola aplikasi.";
          }

          if (message && message.length) {
            const msg = {
              title: "Galat",
              text: message,
              type: "error",
            };
            context.$store.commit("addNotification", msg);
          }
        });
    },

    onDownload() {
      let leftDPenerima = [];
      let leftDPenerimaSekda = [];
      let rightDPenerima = [];
      let rightDPenerimaSekda = [];
      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          rightDPenerima.push(element);
        }
      });
      let DPenerima = [[]];
      let DPenerimaSekda = [[]];

      for (var i = 0; i < leftDPenerima.length; i++) {
        DPenerima[[i]] = [
          {
            text: leftDPenerima[i] ? leftDPenerima[i].text : "",
          },
          {
            text: rightDPenerima[i] ? rightDPenerima[i].text : "",
          },
        ];
      }

      for (var i = 0; i < 10; i++) {
        DPenerimaSekda[[i]] = [
          {
            // text: leftDPenerima[i] ? leftDPenerima[i].text : "",
            // text: leftDPenerimaSekda[i]
            //   ? leftDPenerimaSekda[i].text
            //   : "                                         ",
            text: "                                             ",
          },
          // {
          //   text: rightDPenerima[i] ? rightDPenerima[i].text : "",
          // },
        ];
      }
      var no = 0;
      let DMPenerima = [[]];
      let firstDPenerima = [];
      let twiceDPenerima = [];
      let thirdDPenerima = [];

      let kiriDMPenerima = [];
      let tengahDMPenerima = [];
      let kananDMPenerima = [];
      let DMInstructionMenluKiri = [];

      let kiriDataInstructionMenlu = [];

      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          kiriDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 1) {
          tengahDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          kananDMPenerima.push(element);
        }
      });
      const DMlength = this.state.detail.penerimadispo.length;
      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 3) % 3 == 0) {
          firstDPenerima.push(element);
        }
        if ((index + 3) % 3 == 1) {
          twiceDPenerima.push(element);
        }
        if ((index + 3) % 3 == 2) {
          thirdDPenerima.push(element);
        }
      });

      let leftDKoordinasi = [];
      let rightDKoordinasi = [];
      this.state.detail.koordinasi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDKoordinasi.push(element.text);
        } else {
          rightDKoordinasi.push(element.text);
        }
      });
      let DKoordinasi = [[]];
      for (var i = 0; i < leftDKoordinasi.length; i++) {
        DKoordinasi[[i]] = [
          {
            text: leftDKoordinasi[i] ? leftDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightDKoordinasi[i] ? rightDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      let leftInstruction = [];
      let rightInstruction = [];
      this.state.detail.intruksi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftInstruction.push(element.text);
        } else {
          rightInstruction.push(element.text);
        }
      });
      let Instruction = [[]];

      for (var i = 0; i < leftInstruction.length; i++) {
        Instruction[[i]] = [
          {
            text: leftInstruction[i] ? leftInstruction[i] : "",
          },
          {
            text: rightInstruction[i] ? rightInstruction[i] : "",
          },
        ];
      }
      var documentDefinition = {};
      const user = JSON.parse(localStorage.getItem("user"));
      if (
        user.eselon == "Eselon II" ||
        user.eselon == "Eselon III" ||
        user.eselon == "Eselon IV" ||
        user.eselon == null
      ) {
        documentDefinition = {
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "Lembar Disposisi",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [150, 100, 100, 130],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "INDEKS",
                            bold: true,
                          },
                          {
                            text: this.state.detail.indeks1,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "KODE",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.kode,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NO. URUT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.no_urut,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL. PENYELESAIAN",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_selesai,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "PERIHAL / ISI RINGKAS :",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.description,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "ASAL SURAT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.asal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NOMOR",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.nomor_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "LAMPIRAN",
                            style: "header2",
                          },
                          {
                            text: "",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "DIAJUKAN / DITERUSKAN",
                            style: "header2",
                          },
                          {
                            table: {
                              // headerRows: 0,
                              body: DPenerima,
                            },
                          },
                          {
                            text: "Isi Disposisi",
                            style: "header2",
                          },
                          {
                            table: {
                              // headerRows: 0,
                              body: Instruction,
                            },
                          },
                        ],
                      ],
                    },
                    {},
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "INFORMASI / INSTRUKSI",
                            style: "header2",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                          {
                            text: "",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                ],
              },
            },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            headerTitleRight: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "right",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      } else if (user.eselon == "Eselon I") {
        documentDefinition = {
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "Lembar Disposisi",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [150, 100, 100, 130],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "INDEKS",
                            bold: true,
                          },
                          {
                            text: this.state.detail.indeks1,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "KODE",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.kode,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NO. URUT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.no_urut,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL. PENYELESAIAN",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_selesai,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "PERIHAL / ISI RINGKAS :",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.description,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "ASAL SURAT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.asal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NOMOR",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.nomor_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "LAMPIRAN",
                            style: "header2",
                          },
                          {
                            text: "",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "Kepada Yth.",
                            style: "header2",
                          },
                          {
                            text: "Asisten Pemerintahan & Admin Umum",
                            style: "header2",
                          },
                          {
                            text: "Asisten Perekonomian & Pembangunan",
                            style: "header2",
                          },
                          {
                            text: "Asisten Pemberdayaan SD Masyarakat",
                            style: "header2",
                          },
                          {
                            text: "Paniradya Keistimewaan (Paniradya Kaistimewaan)",
                            style: "header2",
                          },
                          {
                            text: "Staf Ahli Gubernur Bidang ..............",
                            style: "header2",
                          },
                          {
                            text: "........................................",
                            style: "header2",
                          },
                          {
                            text: "........................................",
                            style: "header2",
                          },
                          {
                            text: "........................................",
                            style: "header2",
                          },
                          {
                            text: "Isi Disposisi",
                            style: "header2",
                          },
                          {
                            table: {
                              // headerRows: 0,
                              body: Instruction,
                            },
                          },
                        ],
                      ],
                    },
                    {},
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "INFORMASI / INSTRUKSI",
                            style: "header2",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                          {
                            text: "",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                ],
              },
            },
            {
              text: "Atas perhatian Saudara kami ucapkan terima kasih",
              style: "header3",
            },
            {
              text: "Yogyakarta, ...............................",
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penerima_surat,
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penandatangan_nama,
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penandatangan_nip,
              style: "headerTitleRight",
            },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            headerTitleRight: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "right",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      }

      pdfMake.createPdf(documentDefinition).print();
    },
    onDownloadGubernur() {
      let leftDPenerima = [];
      let leftDPenerimaSekda = [];
      let rightDPenerima = [];
      let rightDPenerimaSekda = [];
      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          rightDPenerima.push(element);
        }
      });
      let DPenerima = [[]];
      let DPenerimaSekda = [[]];

      for (var i = 0; i < leftDPenerima.length; i++) {
        DPenerima[[i]] = [
          {
            text: leftDPenerima[i] ? leftDPenerima[i].text : "",
          },
          {
            text: rightDPenerima[i] ? rightDPenerima[i].text : "",
          },
        ];
      }

      for (var i = 0; i < 10; i++) {
        DPenerimaSekda[[i]] = [
          {
            // text: leftDPenerima[i] ? leftDPenerima[i].text : "",
            // text: leftDPenerimaSekda[i]
            //   ? leftDPenerimaSekda[i].text
            //   : "                                         ",
            text: "                                             ",
          },
          // {
          //   text: rightDPenerima[i] ? rightDPenerima[i].text : "",
          // },
        ];
      }
      var no = 0;
      let DMPenerima = [[]];
      let firstDPenerima = [];
      let twiceDPenerima = [];
      let thirdDPenerima = [];

      let kiriDMPenerima = [];
      let tengahDMPenerima = [];
      let kananDMPenerima = [];
      let DMInstructionMenluKiri = [];

      let kiriDataInstructionMenlu = [];

      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          kiriDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 1) {
          tengahDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          kananDMPenerima.push(element);
        }
      });
      const DMlength = this.state.detail.penerimadispo.length;
      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 3) % 3 == 0) {
          firstDPenerima.push(element);
        }
        if ((index + 3) % 3 == 1) {
          twiceDPenerima.push(element);
        }
        if ((index + 3) % 3 == 2) {
          thirdDPenerima.push(element);
        }
      });

      let leftDKoordinasi = [];
      let rightDKoordinasi = [];
      this.state.detail.koordinasi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDKoordinasi.push(element.text);
        } else {
          rightDKoordinasi.push(element.text);
        }
      });
      let DKoordinasi = [[]];
      for (var i = 0; i < leftDKoordinasi.length; i++) {
        DKoordinasi[[i]] = [
          {
            text: leftDKoordinasi[i] ? leftDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightDKoordinasi[i] ? rightDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      let leftInstruction = [];
      let rightInstruction = [];
      this.state.detail.intruksi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftInstruction.push(element.text);
        } else {
          rightInstruction.push(element.text);
        }
      });
      let Instruction = [[]];

      for (var i = 0; i < leftInstruction.length; i++) {
        Instruction[[i]] = [
          {
            text: leftInstruction[i] ? leftInstruction[i] : "",
          },
          {
            text: rightInstruction[i] ? rightInstruction[i] : "",
          },
        ];
      }
      var documentDefinition = {};
      const user = JSON.parse(localStorage.getItem("user"));
        documentDefinition = {
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "Lembar Disposisi",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [150, 100, 100, 130],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "INDEKS",
                            bold: true,
                          },
                          {
                            text: this.state.detail.indeks1,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "KODE",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.kode,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NO. URUT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.no_urut,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL. PENYELESAIAN",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_selesai,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "PERIHAL / ISI RINGKAS :",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.description,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "ASAL SURAT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.asal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NOMOR",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.nomor_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "LAMPIRAN",
                            style: "header2",
                          },
                          {
                            text: "",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "Kepada Yth. Bapak Gubernur Daerah Istimewa Yogyakarta",
                            style: "headerP",
                          }
                        ]
                      ]
                    }
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "Kami haturkan dengan hormat untuk menjadikan periksa, mohon petunjuk",
                            style: "headerP1",
                          }
                        ]
                      ]
                    }
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [505],
                headerRows: 0,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                        ],
                      ],
                    },
                  ],
                 
                ],
              },
            },
            {
              text: "Yogyakarta, ...............................",
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penerima_surat,
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penandatangan_nama,
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penandatangan_nip,
              style: "headerTitleRight",
            },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            headerTitleRight: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "right",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerP: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
            headerP1: {
              bold: false,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      // } else if (user.eselon == "Eselon I") {
      //   documentDefinition = {
      //     content: [
      //       {
      //         marginLeft: 50,
      //         marginRight: 50,
      //         table: {
      //           headerRows: 1,
      //           widths: [100, "*", 100, "*"],
      //           body: [
      //             [
      //               {
      //                 text: "Lembar Disposisi",
      //                 style: "tableHeader",
      //                 colSpan: 4,
      //                 margin: [20, 10, 0, 15],
      //               },
      //             ],
      //           ],
      //         },
      //         layout: "headerLineOnly",
      //       },
      //       {
      //         style: "tableExample",
      //         color: "#444",
      //         table: {
      //           widths: [150, 100, 100, 130],
      //           headerRows: 1,
      //           body: [
      //             [
      //               {
      //                 text: "",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "INDEKS",
      //                       bold: true,
      //                     },
      //                     {
      //                       text: this.state.detail.indeks1,
      //                       style: "header3",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "KODE",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.kode,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "NO. URUT",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.no_urut,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "TGL. PENYELESAIAN",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.tanggal_selesai,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //             ],
      //             [
      //               {
      //                 colSpan: 4,
      //                 columns: [
      //                   [
      //                     {
      //                       text: "PERIHAL / ISI RINGKAS :",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.description,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {},
      //               {},
      //               {},
      //             ],
      //             [
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "ASAL SURAT",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.asal_surat,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "TGL",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.tanggal_surat,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "NOMOR",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: this.state.detail.nomor_surat,
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {
      //                 text: "",
      //                 style: "tableHeader",
      //                 alignment: "left",
      //                 columns: [
      //                   [
      //                     {
      //                       text: "LAMPIRAN",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "",
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //             ],
      //             [
      //               {
      //                 colSpan: 2,
      //                 columns: [
      //                   [
      //                     {
      //                       text: "Kepada Yth.",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "Asisten Pemerintahan & Admin Umum",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "Asisten Perekonomian & Pembangunan",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "Asisten Pemberdayaan SD Masyarakat",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "Paniradya Keistimewaan",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "Staf Ahli Gubernur Bidang ..........",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "........................................",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "........................................",
      //                       style: "header2",
      //                     },
      //                     {
      //                       text: "........................................",
      //                       style: "header2",
      //                     },
      //                     // {
      //                     //   // table: {
      //                     //   //   // headerRows: 0,
      //                     //   //   body: DPenerimaSekda,
      //                     //   //   style: "border: 0",
      //                     //   // },

      //                     //   // text: "DIAJUKAN / DITERUSKAN",
      //                     //   // style: "header2",
      //                     //   text: "",
      //                     //   style: "tableHeader",
      //                     //   alignment: "left",
      //                     //   columns: [
      //                     //     [
      //                     //       {
      //                     //         text: "Asisten Pemerintahan & Admin Umum",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "Asisten Perekonomian & Pembangunan",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "Asisten Pemberdayaan SD Masyarakat",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "Paniradya Keistimewaan",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "Staf Ahli Gubernur Bidang ..........",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "........................................",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "........................................",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //     [
      //                     //       {
      //                     //         text: "........................................",
      //                     //         style: "header2",
      //                     //       },
      //                     //     ],
      //                     //   ],
      //                     // },
      //                     {
      //                       text: "Isi Disposisi",
      //                       style: "header2",
      //                     },
      //                     {
      //                       table: {
      //                         // headerRows: 0,
      //                         body: Instruction,
      //                       },
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {},
      //               {
      //                 colSpan: 2,
      //                 columns: [
      //                   [
      //                     {
      //                       text: "INFORMASI / INSTRUKSI",
      //                       style: "header2",
      //                       rowSpan: 3,
      //                       colSpan: 2,
      //                     },
      //                     {
      //                       text: "",
      //                       style: "header2",
      //                     },
      //                   ],
      //                 ],
      //               },
      //               {},
      //             ],
      //           ],
      //         },
      //       },
      //       {
      //         text: "Atas perhatian Saudara kami ucapkan terima kasih",
      //         style: "header3",
      //       },
      //       {
      //         text: "Yogyakarta, ...............................",
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: this.state.detail.penerima_surat,
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: " ",
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: " ",
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: " ",
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: " ",
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: "Drs. R. KADARMANTA BASKARA AJI",
      //         style: "headerTitleRight",
      //       },
      //       {
      //         text: "196302251990031010",
      //         style: "headerTitleRight",
      //       },
      //     ],

      //     styles: {
      //       tableHeader: {
      //         bold: true,
      //         fontSize: 11,
      //         color: "black",
      //         alignment: "center",
      //       },
      //       tableHeader1: {
      //         bold: true,
      //         fontSize: 10,
      //         color: "black",
      //         alignment: "left",
      //       },
      //       headerTitle: {
      //         height: 10,
      //         fontSize: 12,
      //         color: "black",
      //         bold: true,
      //       },
      //       headerTitleCenter: {
      //         height: 10,
      //         fontSize: 12,
      //         color: "black",
      //         bold: true,
      //         alignment: "center",
      //       },
      //       headerTitleRight: {
      //         height: 10,
      //         fontSize: 12,
      //         color: "black",
      //         bold: true,
      //         alignment: "right",
      //       },
      //       header: {
      //         bold: true,
      //         fontSize: 26,
      //         color: "black",
      //         alignment: "center",
      //       },
      //       tableExample: {
      //         margin: [0, 5, 0, 15],
      //       },
      //       header233: {
      //         fontSize: 8,
      //         color: "black",
      //       },
      //       TableInstruksi: {
      //         fontSize: 8,
      //         color: "black",
      //       },
      //       TableInstruksi2: {
      //         fontSize: 4,
      //         color: "black",
      //       },
      //       header1: {
      //         bold: true,
      //         fontSize: 8,
      //         color: "black",
      //         alignment: "center",
      //       },
      //       header2: {
      //         bold: true,
      //         fontSize: 10,
      //         color: "black",
      //         alignment: "left",
      //       },
      //       header4: {
      //         bold: true,
      //         fontSize: 8,
      //         color: "black",
      //         alignment: "left",
      //       },
      //       tableTitleDetail: {
      //         bold: true,
      //         fontSize: 12,
      //         color: "black",
      //         alignment: "center",
      //       },
      //     },
      //     pageMargins: [40, 60, 40, 60],
      //     pageSize: "A4",
      //   };
      // }

      pdfMake.createPdf(documentDefinition).print();
    },
    onDownloadWaGub() {
      let leftDPenerima = [];
      let leftDPenerimaSekda = [];
      let rightDPenerima = [];
      let rightDPenerimaSekda = [];
      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          rightDPenerima.push(element);
        }
      });
      let DPenerima = [[]];
      let DPenerimaSekda = [[]];

      for (var i = 0; i < leftDPenerima.length; i++) {
        DPenerima[[i]] = [
          {
            text: leftDPenerima[i] ? leftDPenerima[i].text : "",
          },
          {
            text: rightDPenerima[i] ? rightDPenerima[i].text : "",
          },
        ];
      }

      for (var i = 0; i < 10; i++) {
        DPenerimaSekda[[i]] = [
          {
            text: "                                             ",
          },
        ];
      }
      var no = 0;
      let DMPenerima = [[]];
      let firstDPenerima = [];
      let twiceDPenerima = [];
      let thirdDPenerima = [];

      let kiriDMPenerima = [];
      let tengahDMPenerima = [];
      let kananDMPenerima = [];
      let DMInstructionMenluKiri = [];

      let kiriDataInstructionMenlu = [];

      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          kiriDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 1) {
          tengahDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          kananDMPenerima.push(element);
        }
      });
      const DMlength = this.state.detail.penerimadispo.length;
      this.state.detail.penerimadispo.map(function (element, index) {
        if ((index + 3) % 3 == 0) {
          firstDPenerima.push(element);
        }
        if ((index + 3) % 3 == 1) {
          twiceDPenerima.push(element);
        }
        if ((index + 3) % 3 == 2) {
          thirdDPenerima.push(element);
        }
      });

      let leftDKoordinasi = [];
      let rightDKoordinasi = [];
      this.state.detail.koordinasi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDKoordinasi.push(element.text);
        } else {
          rightDKoordinasi.push(element.text);
        }
      });
      let DKoordinasi = [[]];
      for (var i = 0; i < leftDKoordinasi.length; i++) {
        DKoordinasi[[i]] = [
          {
            text: leftDKoordinasi[i] ? leftDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightDKoordinasi[i] ? rightDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      let leftInstruction = [];
      let rightInstruction = [];
      this.state.detail.intruksi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftInstruction.push(element.text);
        } else {
          rightInstruction.push(element.text);
        }
      });
      let Instruction = [[]];

      for (var i = 0; i < leftInstruction.length; i++) {
        Instruction[[i]] = [
          {
            text: leftInstruction[i] ? leftInstruction[i] : "",
          },
          {
            text: rightInstruction[i] ? rightInstruction[i] : "",
          },
        ];
      }
      var documentDefinition = {};
      const user = JSON.parse(localStorage.getItem("user"));
        documentDefinition = {
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "Lembar Disposisi",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [150, 100, 100, 130],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "INDEKS",
                            bold: true,
                          },
                          {
                            text: this.state.detail.indeks1,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "KODE",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.kode,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NO. URUT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.no_urut,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL. PENYELESAIAN",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_selesai,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "PERIHAL / ISI RINGKAS :",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.description,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "ASAL SURAT",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.asal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "TGL",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "NOMOR",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.nomor_surat,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "LAMPIRAN",
                            style: "header2",
                          },
                          {
                            text: "",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "Kepada Yth. Bapak Wakil Gubernur Daerah Istimewa Yogyakarta",
                            style: "headerP",
                          }
                        ]
                      ]
                    }
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "Kami haturkan dengan hormat untuk menjadikan periksa, mohon petunjuk",
                            style: "headerP1"
                          }
                        ]
                      ]
                    }
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [505],
                headerRows: 0,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                          {
                            text: " ",
                            bold: true,
                          },
                          {
                            text: " ",
                            style: "header3",
                          },
                        ],
                      ],
                    },
                  ],
                 
                ],
              },
            },
            {
              text: "Yogyakarta, ...............................",
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penerima_surat,
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: " ",
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penandatangan_nama,
              style: "headerTitleRight",
            },
            {
              text: this.state.detail.penandatangan_nip,
              style: "headerTitleRight",
            },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            headerTitleRight: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "right",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerP: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
            headerP1: {
              bold: false,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      

      pdfMake.createPdf(documentDefinition).print();
    },
  },
  watch: {
    $route: function (search) {
      this.detailLoad();
    },
  },
};
</script>
